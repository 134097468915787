/**NOTE: This file and any imports in it will not be included in the production build */
import { createApp } from "vue";
import i18n from "@/i18n";
import { pinia } from "~/store";
import App from "./App.vue";
import router from "./router";
import { VueQueryPlugin } from "vue-query";
import eventbusPlugin from "./plugins/eventbus";

const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(eventbusPlugin);
app.use(VueQueryPlugin);

app.mount("#app");
