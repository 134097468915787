import { useMutation, useQuery, useQueryClient } from "vue-query";
import useApi from "../useApi";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import { MyCoVehicleResponse } from "@/models/api/MyCoVehicleResponse";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";

const useGetVehiclesQuery = () => {
    const { get, post } = useApi();
    const queryClient = useQueryClient();

    const getVehicles = () => {
        return get<MyCoVehicleResponse[]>("profile/vehicles", "").then((resp) => {
            return resp.data.map((v) => new MyCoVehicle(v));
        });
    };

    const addNewVehicleMutation = useMutation(
        (vehicle: MyCoVehicle) => {
            return post("profile/vehicles", vehicle);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("vehicles");
            },
        },
    );

    const userStore = useUserStore();
    const cacheKey = computed(() => ["vehicles", userStore.user.userName]);

    return {
        ...useQuery(cacheKey.value, getVehicles, {
            enabled: !!userStore.user.userName,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: false,
        }),
        addNewVehicleMutation,
    };
};

export default useGetVehiclesQuery;
