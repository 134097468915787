import { defineStore } from "pinia";
import { DepartureState } from "@/models/front/TicketState";
import { Direction } from "@/models/Enums";
import { useBookingStore } from "./booking";
import { dateAndTime } from "@/helpers/dateHelpers";

const getInitialState = () => {
    return {
        outward: {
            id: "",
            identifier: "",
            date: "",
        } as DepartureState,
        return: {
            id: "",
            identifier: "",
            date: "",
        } as DepartureState,
    };
};

export const useDepartureStore = defineStore("departure", {
    state: () => getInitialState(),
    getters: {
        getDeparture:
            (state) =>
            (direction: Direction): DepartureState => {
                if (direction === Direction.Outward) {
                    return state.outward;
                } else {
                    return state.return;
                }
            },
        getDepartureState: (state) => {
            return state;
        },
        getDepartureIdentifiers: (state) => (): string[] => {
            const identifiers = [state.outward.identifier];
            const bookingStore = useBookingStore();
            if (bookingStore.getIsReturn) {
                identifiers.push(state.return.identifier);
            }
            return identifiers;
        },
    },
    actions: {
        setDeparture(direction: Direction, departure: DepartureState) {
            if (direction === Direction.Outward) {
                return (this.outward = departure);
            } else {
                return (this.return = departure);
            }
        },
        setAmendmentDepartures(direction: Direction, id?: string) {
            if (id) {
                if (direction === Direction.Outward) {
                    this.outward.id = id;
                } else {
                    this.return.id = id;
                }
            }
        },
    },
    persist: {
        storage: sessionStorage,
    },
});
