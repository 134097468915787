import { UserType } from "../Enums";
import { UserLoginResponse } from "../api/UserLoginResponse";
import { UserDetails } from "./UserDetails";

export class User {
    type: UserType;
    userName: string;
    name: string;
    id: string;
    userDetails?: UserDetails;

    constructor(resp?: UserLoginResponse) {
        this.type = resp?.type == "Customer" ? UserType.Customer : UserType.Agent;
        this.userName = resp?.userName ?? "";
        this.name = resp?.name ?? "";
        this.id = resp?.id ?? "";
    }
}

export type UserState = {
    user: User;
};
