import { CardType, PassengerType, ResourceType } from "../Enums";
import { BookingResourceResponse } from "../api/BookingModelResponse";

export class BookingResource {
    description: string;
    resourceType: ResourceType;
    type?: PassengerType;
    amount?: number;
    width?: number;
    length?: number;
    license?: string;
    price?: number;
    isCard?: boolean;
    cardType?: CardType;
    cardSubType?: string;
    isTrailer: boolean;

    constructor(resp: BookingResourceResponse) {
        this.type = resp.type as PassengerType;
        this.resourceType = resp.resourceType ?? ResourceType.Unknown;
        this.description = resp.description;
        this.amount = resp.amount ?? 0;
        this.length = resp.length ?? 0;
        this.width = resp.width ?? 0;
        this.license = resp.licenseNumber ?? "";
        this.price = resp.price ?? 0;
        this.isCard = this.resourceType === ResourceType.Unknown;
        this.cardType = getCardType(this.description);
        this.cardSubType = getCardSubType(this.description, this.isCard);
        this.isTrailer = resp.isTrailer;
    }
}

export const getCardType = (description: string) => {
    if (description.includes("Partibiljett")) return CardType.MultiTripCard;
    if (description.includes("Årskort")) return CardType.TravelCard;
    else return CardType.Unknown;
};

export const getCardSubType = (description: string, isCard?: boolean) => {
    if (isCard) {
        if (description.includes("fordon")) return "Vehicle";
        else if (description.includes("person")) return "Passenger";
    } else return description;
};
