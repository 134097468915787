import { CalculateResponse } from "@/models/api/CalculateResponse";
import { Basket } from "@/models/front/Basket";
import { useDepartureStore } from "@/store/departure";
import { computed } from "vue";
import { useQuery } from "vue-query";
import useApi from "./useApi";
import { useOpenTicketStore } from "@/store/openTicket";

const useCalculateQuery = (reference?: string, isOpenTicket?: boolean) => {
    const { post } = useApi();
    const departureStore = useDepartureStore();
    const openTicketStore = useOpenTicketStore();
	
	const identifiers = computed(() => {
		
		return isOpenTicket ? 
		[openTicketStore.getIdentifier]
		:
	 	departureStore.getDepartureIdentifiers()
	});
	
    const calculate = () => {
        return post<CalculateResponse>("calc", {
            identifiers: identifiers.value,
            reference,
        }).then((resp) => {
            return new Basket(resp.data);
        });
    };

    return {
        ...useQuery(["calc", reference, identifiers], calculate, {
            enabled: true,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            staleTime: 1000 * 60,
            retry: false,
        }),
    };
};

export default useCalculateQuery;
