import { DialogType } from "@/models/Symbols/Dialog";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { ref } from "vue";
import { UserType } from "@/models/Enums";
import { useI18n } from "vue-i18n";
import { useQueryClient } from "vue-query";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";

const useUnloadBookings = (displaySnackbarOnUnload = true) => {
    const { post } = useApi();
    const isLoading = ref(false);
    const bookingStore = useBookingStore();
    const userStore = useUserStore();

    const { displaySnack } = useSnackbar();
    const { t } = useI18n();
    const queryClient = useQueryClient();

    const unload = () => {
        isLoading.value = true;
        return post("unload", "")
            .then(() => {
                bookingStore.resetBookingState();

                if (userStore.getUserType === UserType.Unknown || userStore.getUserType === UserType.Guest) {
                    userStore.$reset();
                }

                if (displaySnackbarOnUnload) {
                    displaySnack({
                        id: "close",
                        message: t("booking.bookingClosed"),
                        type: DialogType.info,
                    });
                }
            })
            .finally(() => {
                queryClient.invalidateQueries("calc");
                isLoading.value = false;
            });
    };

    return {
        unload,
        isLoading,
    };
};

export default useUnloadBookings;
