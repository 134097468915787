import { eventBusKey } from "@/models/Symbols/Eventbus";
import { SnackbarMessage, snackbarMessageKey } from "@/models/Symbols/Snackbar";
import { inject } from "vue";

const useSnackbar = () => {
    const eventBus = inject(eventBusKey);
    const displaySnack = (data: SnackbarMessage) => {
        eventBus?.emit(snackbarMessageKey, data);
    };

    return {
        displaySnack,
    };
};

export default useSnackbar;
