import { dialogKey, DialogMessage } from "@/models/Symbols/Dialog";
import { eventBusKey } from "@/models/Symbols/Eventbus";
import { inject, ref } from "vue";

const useDialog = () => {
    const eventBus = inject(eventBusKey);

    const data = ref<DialogMessage>();
    const show = ref(false);

    eventBus?.on(dialogKey, (event) => {
        const e = event as DialogMessage;
        show.value = true;
        data.value = e;
    });

    const displayDialog = (message: DialogMessage) => {
        eventBus?.emit(dialogKey, message);
    };

    return {
        displayDialog,
        show,
        data,
    };
};

export default useDialog;
