import { VehicleDetails } from "@/models/front/BookRequest";
import { VehicleState } from "@/models/front/TicketState";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        car: {
            license: "",
            length: 0,
            width: 0,
        },
        trailer: {
            license: "",
            length: 0,
            width: 0,
        },
    } as VehicleState;
};

export const useVehiclesStore = defineStore("vehicles", {
    state: () => getInitialState(),
    getters: {
        getVehicles(state): VehicleState {
            return state as VehicleState;
        },
        getVehiclesDetails(state): VehicleDetails[] {
            const vehicles = [];
            if (state.car?.license.length) {
                vehicles.push(new VehicleDetails(state.car.license, false));
            }
            if (state.trailer?.license.length) {
                vehicles.push(new VehicleDetails(state.trailer.license, true));
            }
            return vehicles;
        },
    },
    actions: {
        setVehicles(vehicles: VehicleState) {
            this.car = vehicles.car;
            this.trailer = vehicles.trailer;
        },
    },
    persist: {
        storage: sessionStorage,
    },
});
