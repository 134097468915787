<template>
    <div class="terms">
        <div class="terms-section">
            <p>
                {{ $t("travelTerms.1") }}
            </p>
            <p class="one-line">
                <a href="https://www.ornosjotrafik.se">{{ $t("travelTerms.2") }}</a>

                <span>{{ $t("travelTerms.3") }}</span>
            </p>
            <b>
                {{ $t("travelTerms.4") }}
            </b>
            <b>
                {{ $t("travelTerms.5") }}
            </b>
        </div>
        <div class="terms-section">
            <h4>{{ $t("travelTerms.6.title") }}</h4>
            <ul>
                <li>{{ $t("travelTerms.6.items.1") }}</li>
                <li>{{ $t("travelTerms.6.items.2") }}</li>
                <li>{{ $t("travelTerms.6.items.3") }}</li>
                <li>{{ $t("travelTerms.6.items.4") }}</li>
                <li>{{ $t("travelTerms.6.items.5") }}</li>
                <li>{{ $t("travelTerms.6.items.6") }}</li>
            </ul>
            <ul>
                <li>{{ $t("travelTerms.10.items.1") }}</li>
                <li>{{ $t("travelTerms.10.items.2") }}</li>
                <li>{{ $t("travelTerms.10.items.3") }}</li>
            </ul>
        </div>
        <div class="terms-section">
            <b class="one-line"
                >{{ $t("travelTerms.8") }}
                <a href="https://ornosjotrafik.se/prislista-biljetter/">https://ornosjotrafik.se/prislista-biljetter/</a></b
            >
            <b>{{ $t("travelTerms.9") }}</b>
            <b>{{ $t("travelTerms.12") }}</b>
            <b>{{ $t("travelTerms.13") }}</b>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.terms {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-section {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h4 {
            font-size: 1.25rem;
        }

        ul {
            list-style-type: disc;
            padding-left: 25px;

            @include media-breakpoint-down(sm) {
                padding-left: 15px;
            }

            li + li {
                margin-top: 10px;
            }
        }

        .one-line {
            display: flex;
            flex-flow: row wrap;
            gap: 3px;
        }
    }
}
</style>
