import { dateAndTime } from "@/helpers/dateHelpers";
import { BookingDepartureResponse } from "../api/BookingModelResponse";
import { BookingResource } from "./BookingResource";

export class BookingDeparture {
    departureDate: string;
    route: string;
    resources: BookingResource[];
    totalPrice?: number;
    id?: string;
    isLocked: boolean;

    constructor(resp: BookingDepartureResponse) {
    
        this.departureDate = resp.departureDate;
        this.route = resp.route;
        this.resources = resp.resources.map((r) => new BookingResource(r));
        this.totalPrice = resp.totalPrice;
        this.id = resp.id;
        this.isLocked = resp.isLocked ?? false;
    }
}
