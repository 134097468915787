import { ref } from "vue";
import useApi from "../useApi";

export type VehicleInfoResponse = {
    length: number;
    width: number;
    licenseNumber: string;
};
const useVehicleInfo = () => {
    const { get } = useApi();
    const isLoading = ref(false);

    const getVehicleInfo = (licenseNumber: string) => {
        isLoading.value = true;
        return get<VehicleInfoResponse>(`vehicle/info/${licenseNumber}`, "")
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        getVehicleInfo,
        isLoading,
    };
};

export default useVehicleInfo;
