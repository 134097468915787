import { PassengerState } from "@/models/front/TicketState";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        adult: 0,
    } as PassengerState;
};

export const usePassengersStore = defineStore("passengers", {
    state: () => getInitialState(),
    getters: {
        getPassengers(state): PassengerState {
            return state as PassengerState;
        },
    },
    actions: {
        setPassengers(adults: number) {
            this.adult = adults;
        },
    },
    persist: {
        storage: sessionStorage,
    },
});
