import { UserType } from "@/models/Enums";
import { ref } from "vue";
import useApi from "../useApi";

export type Reset = {
    token: string;
    password: string;
    type: UserType;
};

const useReset = () => {
    const { post } = useApi();
    const isLoading = ref(false);

    const reset = (request: Reset) => {
        isLoading.value = true;
        return post("auth/reset", request)
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        reset,
        isLoading,
    };
};

export default useReset;
