import { BookingCompleteDto } from "../api/BookingCompleteDto";
import { CompleteResponse } from "../api/CompleteResponse";

import { BookingComplete } from "./BookingComplete";

export class Complete {
    isOpenEnded: boolean;
    bookings: BookingComplete[];

    constructor(resp: CompleteResponse) {
        this.isOpenEnded = resp.isOpenEnded;
        this.bookings = resp.bookings.map((booking) => new BookingComplete(booking));
    }
}
