import { useUserStore } from "@/store/user";
import useApi from "../useApi";
import { useBookingStore } from "@/store/booking";

const useLogout = () => {
    const userStore = useUserStore();
    const bookingStore = useBookingStore();
    const { removeToken, post } = useApi();

    const logout = () => {
        post("profile/logout", {}).then(() => {
            userStore.logout();
            removeToken();
            bookingStore.resetBookingState();
			
            window.location.href = "/login";
        });
    };

    return {
        logout,
    };
};

export default useLogout;
