export const getQueryParamValue = (param: string) => {
    const url = new URL(document.location.href);
    const paramValue = url.searchParams.get(param) || false;
    return paramValue ? paramValue : "";
}

export const removeQueryParam = (param: string) => {
    const url = new URL(document.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, document.title, url.href);
}