import { createI18n } from "vue-i18n";
import sv from "../i18n/sv.json";

const locale = document.getElementsByTagName("html")[0].getAttribute("lang");

const i18n = createI18n({
    legacy: false,
    locale: locale || "sv",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sv",
    globalInjection: true,
    messages: { sv },
});

export default i18n;
