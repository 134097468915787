import { PassengerType, ResourceType } from "../Enums";
import { PassengerState, VehicleState } from "./TicketState";
import { BookingAmendmentResponse } from "../api/BookingAmendResponse";
import { BookingDeparture } from "./BookingDeparture";
import { BookingResource } from "./BookingResource";

export class BookingAmend {
    bookingNumber: number;
    totalPrice: number;
    isReturn: boolean;
    outward: BookingDeparture;
    return?: BookingDeparture;
    passengers: PassengerState;
    vehicles: VehicleState;
    isBusiness: boolean;

    constructor(resp: BookingAmendmentResponse) {
        this.bookingNumber = resp.bookingNumber;
        this.totalPrice = resp.totalPrice;
        this.isReturn = resp.departures.length > 1;
        this.outward = new BookingDeparture(resp.departures[0]);
        this.return = resp.departures[1] ? new BookingDeparture(resp.departures[1]) : undefined;
        this.passengers = getPassengers(this.outward.resources);
        this.vehicles = getVehicles(this.outward.resources);
        this.isBusiness = resp.isBusiness;
    }
}

export const getPassengers = (resources: BookingResource[]) => {
    const adults = resources
    			   .filter((r) => r.type === PassengerType.Adult)
    			   .reduce((sum : number, item : any) => sum + (item?.amount ?? 0), 0) ?? 0;

    return {
        adult: adults - 1 /* the CHAU is always there and is free! */,
    };
};

export const getVehicles = (resources: BookingResource[]) => {
    const car = resources.find((r) => r.resourceType === ResourceType.Vehicle && r.isTrailer === false);
    const trailer = resources.find((r) => r.resourceType === ResourceType.Vehicle && r.isTrailer === true);

    return {
        car: {
            license: car?.license ?? "",
            width: car?.width ?? 0,
            length: car?.length ?? 0,
        },
        trailer: {
            license: trailer?.license ?? "",
            width: trailer?.width ?? 0,
            length: trailer?.length ?? 0,
        },
    };
};
