import { AvailableCardsPurchaseResponse } from "../api/AvailableCardsPurchaseResponse";

export class AvailableCardsPurchase {
    identifier: string;
    reference: string;

    constructor(resp: AvailableCardsPurchaseResponse) {
        this.identifier = resp.identifier;
        this.reference = resp.reference;
    }
}
