import { useQuery } from "vue-query";
import useApi from "../useApi";
import { ref } from 'vue';
import { useOpenTicketStore } from "@/store/openTicket";

const useOpenTicketsQuery = () => {
    const { get } = useApi();
	
    const openTicketStore = useOpenTicketStore();
    const getOpenTickets = () => {
        return get<string>("open/ticket", "").then((resp) => {
            openTicketStore.setIdentifier(resp.data);
            return resp.data ?? "";
        });
    };

    return {
        ...useQuery("getOpenTickets", getOpenTickets, {
            refetchOnWindowFocus: false,
            retry: false,
        }),
    };
};

export default useOpenTicketsQuery;
