import { RuntimeConfig } from "@/models/RuntimeConfig";
import axios from "axios";
import { ref } from "vue";

const version = process.env.PACKAGE_VERSION;

const devMode = process.env.NODE_ENV !== "production";
const config = ref<RuntimeConfig>({
    apiBaseUrl: "https://localhost:7233/api/v1/",
    env: "",
    experimental: "",
});
const read = ref(false);

const useRuntimeConfig = () => {
    const fetcher = async () => {
        if (read.value) return config.value;

        const runtimeConfig = await axios.get(
            devMode ? "/runtimeConfig.development.json?" + version : "/runtimeConfig.json?" + version,
        );
        const json = runtimeConfig.data;
        config.value = {
            apiBaseUrl: json.api_base_url,
            env: json.env,
            experimental: json.experimental
        };

        read.value = true;

        return config.value;
    };

    const getRuntimeConfig = async () => {
        return fetcher();
    };

    return {
        getRuntimeConfig,
    };
};

export default useRuntimeConfig;
