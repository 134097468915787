import i18n from "@/i18n";
import { startOfToday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { enGB, sv } from "date-fns/locale";

const locales = { en: enGB, sv };

export const getLocale = () => {
    switch (i18n.global.locale.value) {
        case "sv":
            return locales.sv;
        default:
            return locales.en;
    }
};

export const formatDate = (date: string | Date, dateFormat: string): string => {
    return formatInTimeZone(new Date(date), "Europe/Stockholm", dateFormat, { locale: getLocale() });
};

export const dateAndTime = (date: string | Date): string => {
    return formatDate(new Date(date), "yyyy-MM-dd HH:mm");
};

export const YYYYMMDD = (date: string | Date): string => {
    return formatDate(new Date(date), "yyyy-MM-dd");
};

export const HHmm = (date: string | Date): string => {
    return formatDate(new Date(date), "HH:mm");
};

export const HHmmss = (date: string | Date): string => {
    return formatDate(new Date(date), "HH:mm:ss");
};

export const dd = (date: string | Date): string => {
    return formatDate(new Date(date), "dd");
};

export const eeee = (date: string | Date): string => {
    return formatDate(new Date(date), "eeee");
};

export const MMMM = (date: string | Date): string => {
    return formatDate(new Date(date), "MMMM");
};

export const today = (): Date => {
    return startOfToday();
};

export const dayDateTime = (date: string | Date): string => {
    return formatDate(date, "EEE dd/MM HH:mm");
};

export const dayDate = (date: string | Date): string => {
    return formatDate(date, "EEE dd/MM");
};

export const shortDate = (date: string | Date): string => {
    return formatDate(date, "d/MM YYYY");
};
