import { BookingResponse } from "../api/BookingModelResponse";
import { BookingDeparture } from "./BookingDeparture";

export class Booking {
    bookingNumber: number;
    departure: BookingDeparture;
    token: string;
    return?: BookingDeparture;

    constructor(resp: BookingResponse) {
        this.bookingNumber = resp.bookingNumber;
        this.departure = new BookingDeparture(resp.departures[0]);
        this.token = resp.token;
        this.return = resp.departures[1] ? new BookingDeparture(resp.departures[1]) : undefined;
    }
}
