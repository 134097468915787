import { YYYYMMDD } from "@/helpers/dateHelpers";
import { CardType } from "../Enums";

export class Card {
    travelCards: CustomerCard[];
    multiTripCards: CustomerCard[];

    constructor(resp: CustomerCard[]) {
        this.travelCards = resp.filter((c) => c.type === CardType.TravelCard);
        this.multiTripCards = resp.filter((c) => c.type === CardType.MultiTripCard);
    }
}

export interface CardsResponse {
    cardNumber: string;
    currentUsage: number;
    maxUsage: number;
    name: string;
    subType: string;
    type: string;
    validFrom: Date;
    validTo: Date;
}

export class CustomerCard {
    cardNumber: string;
    currentUsage: number;
    maxUsage: number;
    name: string;
    description: string;
    subType: string;
    validBetween: string;
    type: CardType;

    constructor(resp: CardsResponse) {
        this.cardNumber = resp.cardNumber;
        this.currentUsage = resp.currentUsage;
        this.maxUsage = resp.maxUsage;
        this.name = resp.name;
        this.subType = resp.subType;
        this.validBetween = `${YYYYMMDD(resp.validFrom)} - ${YYYYMMDD(resp.validTo)}`;
        this.type = getCardType(resp.type);
        this.description = "";
    }
}

export const getCardType = (type: string) => {
    if (type === "TravelCard") return CardType.TravelCard;
    if (type === "MultiTripCard") return CardType.MultiTripCard;
    else return CardType.Unknown;
};
