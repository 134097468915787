import { CreateCustomerRequest } from "@/models/api/CreateCustomerRequest";
import { ref } from "vue";
import useApi from "../useApi";
import useLogin from "./useLogin";

const useCreateAccount = () => {
    const isLoading = ref(false);

    const { post } = useApi();
    const { customerLogin } = useLogin();

    const createCustomer = (request: CreateCustomerRequest) => {
        isLoading.value = true;
        return post("profile/create", request)
            .then(() => {
                if (request.email && request.password) {
                    customerLogin(request.email, request.password, 'customer');
                }
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        createCustomer,
        isLoading,
    };
};

export default useCreateAccount;
