import useApi from "@/composables/useApi";
import { Direction } from "@/models/Enums";
import { DepartureModelResponse } from "@/models/api/DepartureModelResponse";
import { DepartureModel } from "@/models/front/DepartureModel";
import { VehicleItem } from "@/models/front/TicketState";
import { useBookingStore } from "@/store/booking";
import { usePassengersStore } from "@/store/passengers";
import { useVehiclesStore } from "@/store/vehicles";
import { computed } from "vue";
import { useQuery } from "vue-query";
import PQueue from "p-queue";

export type SearchRequest = {
    route: string;
    startDate: Date;
    returnDate: Date;
    adults: number;
    vehicles: VehicleItem[];
    isBusiness: boolean;
};

const useDepartureQuery = (direction: Direction) => {

	const queue = new PQueue({ concurrency: 1 });
	
    const { post } = useApi();
    const bookingStore = useBookingStore();
    const passengerStore = usePassengersStore();
    const vehicleStore = useVehiclesStore();

    const request = computed(() => {
        const date = bookingStore.getDate(direction);

        if (!date) return;
        
        const req: SearchRequest = {
            route: bookingStore.getRoute(direction),
            startDate: date,
            returnDate: date,
            isBusiness: bookingStore.isBusiness,
            adults: passengerStore.getPassengers.adult,
            vehicles: [],
        };
        if (vehicleStore.getVehicles.car?.length) {
            req.vehicles?.push(vehicleStore.getVehicles.car);
        }
        if (vehicleStore.getVehicles.trailer?.length) {
            req.vehicles?.push({...vehicleStore.getVehicles.trailer, isTrailer: true });
        }
        return req;
    });

    return {
        ...useQuery({
        	queryKey: ["departures", request], 
        	queryFn: ({ signal }) => {
        		return queue.add(() => 
	        		post<DepartureModelResponse[]>("prices/search", request.value, {}, signal).then((resp) => 
	            		resp.data?.map((departure) => new DepartureModel(departure))
	        		)
	        	);
        	},
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: false,
        }),
    };
};

export default useDepartureQuery;
