export type OptionItem = {
    label: string;
    value: string;
};

export const routes: OptionItem[] = [
    {
        label: "Ornö",
        value: "ORDA",
    },
    {
        label: "Dalarö",
        value: "DAOR",
    },
];

export const departureRoutes = [
    {
        outwardRoute: "Ornö",
        arrivalRoute: "Dalarö",
        value: "ORDA",
    },
    {
        outwardRoute: "Dalarö",
        arrivalRoute: "Ornö",
        value: "DAOR",
    },
];
