import useApi from "@/composables/useApi";
import { BookingDetailsResponse } from "@/models/api/BookingDetailsResponse";
import { BookingDetails } from "@/models/front/BookingDetails";
import { ref } from "vue";
import { useQuery } from "vue-query";

export type BookingDetailsRequest = {
    name: string;
    address: string;
    email: string;
    zip: number;
    mobilePhone: string;
    city: string;
    terms: boolean;
    bookingNumber: number;
};

const useBookingDetailsQuery = () => {
    const { get, post } = useApi();
    const isLoading = ref(false);

    const getDetails = () => {
        return get<BookingDetailsResponse>("booking/details", "").then((response) => {
            return new BookingDetails(response.data);
        });
    };

    const setDetails = (req: BookingDetailsRequest) => {
        isLoading.value = true;

        return post("booking/details", req).finally(() => {
            isLoading.value = false;
        });
    };

    return {
        ...useQuery(["details"], getDetails, {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
        }),
        setDetails,
    };
};

export default useBookingDetailsQuery;
