export enum TripType {
    Single,
    Return,
}
export enum Direction {
    Outward,
    Return,
}
export enum UserType {
    Unknown,
    Customer,
    Agent,
    Guest,
}
export enum TravelCardType {
    Unknown,
    Vehicle,
    Passenger,
}

export enum PaymentAlternativeType {
    Cash = "Cash",
    TravelCard = "Card",
    Invoice = "Invoice",
}

export enum CardType {
    TravelCard,
    MultiTripCard,
    Unknown,
}
export enum VehicleCapacity {
    Plenty = "Plenty",
    Limited = "Limited",
    None = "None",
}
export enum ResourceType {
    Unknown,
    Passenger,
    Vehicle,
}
export enum PassengerType {
    Adult = "A",
}
export enum TravelType {
    Private,
    Company,
    Freight,
}
