import { Direction } from "@/models/Enums";
import { BookingAmendmentResponse } from "@/models/api/BookingAmendResponse";
import { BookingResponse } from "@/models/api/BookingModelResponse";
import { Booking } from "@/models/front/Booking";
import { BookingAmend } from "@/models/front/BookingAmend";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import { usePassengersStore } from "@/store/passengers";
import { useUserStore } from "@/store/user";
import { useVehiclesStore } from "@/store/vehicles";
import { computed, ref } from "vue";
import { useQuery } from "vue-query";
import useApi from "../useApi";

export type BookingAmendRequest = {
    bookingNumber: number;
    token: string;
    email: string;
};

const useGetBookingsQuery = () => {
    const { post } = useApi();

    const bookingStore = useBookingStore();
    const passengerStore = usePassengersStore();
    const vehicleStore = useVehiclesStore();
    const departureStore = useDepartureStore();

    const getBookings = () => {
        return post<BookingResponse[]>("profile/bookings", { startDate: new Date() }).then((resp) => {
            return resp.data.map((b) => new Booking(b));
        });
    };

    const getPastBookings = () => {
        return post<BookingResponse[]>("profile/bookings", { endDate: new Date() }).then((resp) => {
            return resp.data.map((b) => new Booking(b));
        });
    };

    const isLoadingOpen = ref(false);
    const amendBookingOpen = (request: BookingAmendRequest) => {
        isLoadingOpen.value = true;
        return post<BookingAmendmentResponse>("amend/open", request)
            .then((resp) => {
                const amendment = new BookingAmend(resp.data);
                bookingStore.setAmendment(amendment);
                passengerStore.setPassengers(amendment.passengers.adult);
                vehicleStore.setVehicles(amendment.vehicles);

                departureStore.setAmendmentDepartures(Direction.Outward, bookingStore.outward.id);
                if (bookingStore.getIsReturn) {
                    departureStore.setAmendmentDepartures(Direction.Return, bookingStore.return.id);
                }
                return resp.data;
            })
            .finally(() => {
                isLoadingOpen.value = false;
            });
    };

    const userStore = useUserStore();
    const cacheKey = computed(() => ["bookings", userStore.user.userName]);

    return {
        ...useQuery(cacheKey.value, getBookings, {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 60,
        }),
        amendBookingOpen,
        isLoadingOpen,
        getPastBookings,
    };
};

export default useGetBookingsQuery;
