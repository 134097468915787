import startOfDay from "date-fns/startOfDay";
import { today } from "./dateHelpers";
import i18n from "@/config/i18n";
import * as validators from "@vuelidate/validators";

const { createI18nMessage } = validators;
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);

export const outwardDateRequired = (outwardDate?: Date) => {
    return !!outwardDate && outwardDate >= today();
};

export const returnDateRequired = (isReturn: boolean, returnDate?: Date, outwardDate?: Date) => {
    return !isReturn || (!!returnDate && startOfDay(returnDate) >= startOfDay(outwardDate ?? new Date()));
};
