import { CalculateResponse } from "../api/CalculateResponse";
import { BookingDeparture } from "./BookingDeparture";

export class Basket {
    sections: BookingDeparture[];
    leftToPay: number;
    totalPrice: number;
    paid: number;
    amd: number;
    
    constructor(resp: CalculateResponse) {
        this.sections = resp.departures.map((d) => new BookingDeparture(d));
        this.leftToPay = resp.leftToPay;
        this.totalPrice = resp.totalPrice;
        this.paid = resp.paid;
        this.amd = resp.amendmentFee;
    }
}
