import { UserType } from "@/models/Enums";
import { UserState, User } from "@/models/front/User";
import { UserDetails } from "@/models/front/UserDetails";
import { defineStore } from "pinia";

const getInitialState = () => {
    return {
        user: {
            type: UserType.Unknown,
            id: "",
            userDetails: {
                firstName: "",
                lastName: "",
                address: "",
                zip: 0,
                city: "",
                mobilePhone: "",
                email: "",
            },
        },
    } as UserState;
};

export const useUserStore = defineStore("user", {
    state: () => {
        return getInitialState();
    },
    getters: {
        getUserType(): UserType {
            return this.user.type;
        },
        getUserDetails(): UserDetails | undefined {
            return this.user.userDetails;
        },
        isLoggedIn(): boolean {
            return this.user.type === UserType.Agent || this.user.type === UserType.Customer;
        },
        getUser(): User {
            return this.user;
        },
    },
    actions: {
        setUserDetails(userDetails: UserDetails) {
            this.user.userDetails = userDetails;
        },
        setUserType(type: UserType) {
            this.user.type = type;
        },
        login(user: User) {
            this.user = user;
        },
        logout() {
            this.$state = getInitialState();
        },
    },
    persist: {
        storage: localStorage,
        paths: ["user"],
    },
});
