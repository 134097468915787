import { UserDetails } from "@/models/front/UserDetails";
import useApi from "../useApi";
import useLogin from "../auth/useLogin";
import { ref } from "vue";

const useProfileDetails = () => {
    const { post } = useApi();
    const isLoading = ref(false);

    const { getUserDetails } = useLogin();

    const updateDetails = (details: UserDetails) => {
        isLoading.value = true;
        return post("profile/details", details)
            .then(() => {
                getUserDetails();
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    return {
        updateDetails,
        isLoading,
    };
};

export default useProfileDetails;
