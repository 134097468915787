import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import BookingFlow from "../modules/BookingFlow/App.vue";
import AgentBookingFlow from "../modules/BookingFlow/components/AgentBookingFlow.vue";
import OpenTicketFlow from "../modules/BookingFlow/components/OpenTicketFlow.vue";
import MyPages from "../modules/MyPages/App.vue";
import LoginPage from "@/modules/Login/LoginPage.vue";
import AgentLoginPage from "@/modules/Login/AgentLoginPage.vue";
import ThankYou from "@/modules/ThankYou/App.vue";
import Checkout from "../modules/Checkout/App.vue";
import ResetPassword from "@/modules/reset/ResetPassword.vue";
import TimeTable from "@/modules/TimeTable/App.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: BookingFlow,
    },
    {
        path: "/company",
        component: AgentBookingFlow,
    },
    {
        path: "/open-ticket",
        component: OpenTicketFlow,
    },
    {
        path: "/my-pages",
        component: MyPages,
    },
    {
        path: "/login",
        component: LoginPage,
    },
    {
        path: "/agent-login",
        component: AgentLoginPage,
    },
    {
        path: "/done",
        component: ThankYou,
    },
    {
        path: "/ticket",
        component: ThankYou,
        props: { isSessionless: true },
    },
    {
        path: "/checkout",
        component: Checkout,
    },
    {
        path: "/reset",
        component: ResetPassword,
    },
    {
        path: "/timetable",
        component: TimeTable,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

export default router;
