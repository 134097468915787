<template>
    <div class="site-hero">
        <figure>
            <img src="../../assets/images/SiteHeroImage.jpg" alt="Hero image" />
        </figure>
    </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.site-hero {
    display: flex;
    justify-content: center;

    figure {
        opacity: 0.6;
        width: 100%;
        max-height: var(--site-hero__max-height);
    }
}
</style>
