import { ForgotPasswordResponse } from "@/models/api/ForgotPasswordResponse";
import { UserDetailsResponse } from "@/models/api/UserDetailsResponse";
import { UserLoginResponse } from "@/models/api/UserLoginResponse";
import { User } from "@/models/front/User";
import { UserDetails } from "@/models/front/UserDetails";
import { useUserStore } from "@/store/user";
import { ref } from "vue";
import useApi from "../useApi";
import { useQueryClient } from "vue-query";
import { postMessage } from "@/helpers/messageHandler";
    
const useLogin = () => {
    const isLoading = ref(false);
    const userStore = useUserStore();
    const { post, get } = useApi();
    const queryClient = useQueryClient();

    const customerLogin = (username: string, password: string, type: string) => {
        isLoading.value = true;
        return post<UserLoginResponse>("auth", { username, password, type })
            .then(async (resp) => {
            
            	postMessage({ 'type' : 'login', 
            				  'userName': resp.data.userName, 
            				  'token': resp.data.token, 
            				  'userType': resp.data.type
            				  });
            	
                userStore.login(new User(resp.data));
				
				await getUserDetails();
				
				return resp.data;
            })
            .finally(() => {
            
            	isLoading.value = false;
                queryClient.invalidateQueries("details");
            });
    };

    const getUserDetails = () => {
        isLoading.value = true;
        return get<UserDetailsResponse>("profile/details", "")
            .then((resp) => {
                userStore.setUserDetails(new UserDetails(resp.data));
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    const sendForgottenPassword = (userName: string) => {
        isLoading.value = true;
        return post<ForgotPasswordResponse>("auth/forgot", { userName })
            .then(() => {
                isLoading.value = false;
            })
            .catch((err) => {
                return err;
            });
    };

    return {
        customerLogin,
        sendForgottenPassword,
        getUserDetails,
        isLoading,
    };
};

export default useLogin;
