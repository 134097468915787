export class VehicleDetails {
    licenseNumber: string;
    isTow: boolean;

    constructor(licenseNumber: string, isTow: boolean) {
        this.licenseNumber = licenseNumber;
        this.isTow = isTow;
    }
}

export type BookRequest = {
    identifiers: string[];
    vehicleDetails: VehicleDetails[];
    bookingNumber: number;
};
