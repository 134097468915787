import { AvailableCardsPurchaseResponse } from "@/models/api/AvailableCardsPurchaseResponse";
import { AvailableCardResponse } from "@/models/api/AvailableCardsResponse";
import { AvailableCard } from "@/models/front/AvailableCard";
import { AvailableCardsPurchase } from "@/models/front/PurchaseAvailableCards";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import { useQuery } from "vue-query";
import useApi from "../useApi";

const useGetAvailableMultiTripCardQuery = () => {
    const { get, post } = useApi();

    const getAvailableMultiTripCards = () => {
        return get<AvailableCardResponse[]>("profile/cards/available", "").then((resp) => {
            return resp.data.map((a) => new AvailableCard(a));
        });
    };

    const isLoadingBuy = ref(false);
    const buyTickets = (identifier: string) => {
        isLoadingBuy.value = true;
        return post<AvailableCardsPurchaseResponse>("profile/cards/buy", { identifier })
            .then((resp) => {
                return new AvailableCardsPurchase(resp.data);
            })
            .finally(() => {
                isLoadingBuy.value = false;
            });
    };
    const userStore = useUserStore();
    const cacheKey = computed(() => ["availableMultiTripCard", userStore.user.userName]);
    return {
        ...useQuery(cacheKey.value, getAvailableMultiTripCards, {
            refetchOnWindowFocus: false,
            retry: false,
        }),
        buyTickets,
        isLoadingBuy,
    };
};

export default useGetAvailableMultiTripCardQuery;
